import React from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql, Link } from "gatsby"

const StyledServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledSectionTitle = styled.h2`
  color: #da1e35;
  font-size: 32px;
  text-align: center;
  padding: 0 20px;
`

const StyledMagnifierImage = styled(Image)`
  width: 60px;
  margin: 0 25px;
  transition: width 0.2s;

  @media screen and (min-width: 1280px) {
    &:hover {
      width: 68px;
    }
  }
`

const StyledServicesContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: 768px) {
    justify-content: center;
  }
`

const StyledService = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 10px 0;
  width: 100%;

  p {
    color: #da1e35;
    font-size: 18px;
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
    width: auto;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 10px 0;
  width: 100%;

  p {
    color: #da1e35;
    font-size: 18px;
    font-weight: 700;
  }

  @media screen and (min-width: 768px) {
    width: auto;
    padding: 0;
  }
`

const ServicesPage = ({ data }) => {
  return (
    <>
      <StyledServicesWrapper>
        <StyledSectionTitle>USŁUGI</StyledSectionTitle>
        <StyledServicesContainer>
          <StyledLink to="/lakierowanie">
            <StyledService>
              <StyledMagnifierImage
                fluid={data.magnifier.childImageSharp.fluid}
              />
              <p>LAKIEROWANIA</p>
            </StyledService>
          </StyledLink>
          <StyledLink to="/obrobki">
            <StyledService>
              <StyledMagnifierImage
                fluid={data.magnifier.childImageSharp.fluid}
              />
              <p>OBRÓBKI</p>
            </StyledService>
          </StyledLink>
          <StyledLink to="/nadruk">
            <StyledService>
              <StyledMagnifierImage
                fluid={data.magnifier.childImageSharp.fluid}
              />
              <p>NADRUKU</p>
            </StyledService>
          </StyledLink>
          <StyledLink to="/spedycja">
            <StyledService>
              <StyledMagnifierImage
                fluid={data.magnifier.childImageSharp.fluid}
              />
              <p>SPEDYCJI</p>
            </StyledService>
          </StyledLink>
        </StyledServicesContainer>
      </StyledServicesWrapper>
    </>
  )
}

export const query = graphql`
  {
    magnifier: file(name: { eq: "magnifier" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default ServicesPage
